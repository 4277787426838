﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
    hyphens: manual;
}

p {
    margin-top: 0;
}

a {
    text-decoration: underline;
}

h1, .h1 {
    font-family: $font-family-extra;
    font-weight: 800;
    font-size: rem-calc(36);
    line-height: 1.5em;
    margin-bottom: 0.5em;
    margin-top: .75em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(45);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(70);
        line-height: rem-calc(75);
    }

    &--large {
        font-size: rem-calc(45);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(70);
        }
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }
}

h2, .h2 {
    font-family: $font-family-extra;
    font-size: rem-calc(32);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(55);
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }
}

h3, .h3 {
    font-family: $font-family-extra;
    font-size: rem-calc(24);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(40);
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }

    &--small-margin {
        margin-bottom: 0;
        margin-top: 0;
    }
}

h4, .h4 {
    font-family: $font-family-extra;
    font-size: rem-calc(20);
    font-weight: 900;
    line-height: rem-calc(40);
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(30);
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }
}

h5, .h5 {
    font-family: $font-family-extra;
    font-size: rem-calc(18);
    font-weight: 900;
    line-height: rem-calc(35);
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(26);
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }
}

h6, .h6 {
    font-family: $font-family-extra;
    font-size: rem-calc(16);
    font-weight: 900;
    line-height: rem-calc(24);
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(24);
    }

    &--primary {
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");
    }
}

strong {
    font-weight: 700;
}

.text-center {
    text-align: center;
}

.no-wrap {
    white-space: nowrap;
}
