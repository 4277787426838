.impression {
    $elm: &;
    height: 70vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: map-deep-get($colors, "brand", "secondary");
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    margin-top: 0;

    &:not(#{$elm}--no-filter)::before {
        background: rgba(map-deep-get($colors, "brand", "secondary-dark"), 0.3);
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    &--vh100 {
        height: 100vh;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &__video-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__video {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        //transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
    }

    &__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__title {
        position: relative;
        z-index: 2;
        font-weight: 300;
        color: map-deep-get($colors, "white", "default");
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(72);
        }

        &--absolute {
            bottom: 0;
            display: flex;
            max-width: 35vw;
            position: absolute;
            right: 80px;
            top: 0;

            #{$elm}__title-header {
                align-self: center;
                margin: 0;
                text-align: right;
            }
        }

        /* stylelint-disable */
        h1 span {
            font-weight: 100;
        }
        /* stylelint-enable */
    }

    &__content {
        color: map-deep-get($colors, "brand", "primary");
        left: 15%;
        opacity: 0;
        position: absolute;
        top: 45%;
        transition: $transition-default;
        z-index: 1;
    }

    &__list {
        font-family: $font-family-extra;
        font-size: rem-calc(18);
        margin: rem-calc(20) 0 rem-calc(30);
    }

    &__list-item {
        line-height: rem-calc(28);
    }

    &__list-item-label {
        color: map-deep-get($colors, "white", "default");
        margin-left: 5px;
    }

    &__button-scroll {
        cursor: pointer;
        bottom: 5%;
        margin-right: -14px;
        position: absolute;
        right: 50%;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            right: 10%;
        }

        svg {
            stroke: map-deep-get($colors, "white", "default");
        }

        &:hover svg {
            stroke: map-deep-get($colors, "brand", "primary");
        }
    }

    &__shape {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        svg {
            height: 100%;
            width: auto;
        }

        svg path {
            fill: rgba(map-deep-get($colors, "black", "default"), .15);
        }
    }
}

.hero__bg.b-loaded + .impression__content {
    opacity: 1;
}
