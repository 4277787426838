//@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700|Open+Sans:400,700&display=swap");

///* hebrew */
//@font-face {
//    font-family: Heebo;
//    font-style: normal;
//    font-weight: 400;
//    src: local(Heebo), local("Heebo-Regular"), url(https://fonts.gstatic.com/s/heebo/v5/NGS6v5_NC0k9P9H0TbFzsQ.woff2) format("woff2");
//    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
//}

/* latin */
@font-face {
    font-family: Heebo;
    font-style: normal;
    font-weight: 400;
    src: local("Heebo"), local("Heebo-Regular"), url("../../../fonts/heebo/heebo-regular.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

///* hebrew */
//@font-face {
//    font-family: Heebo;
//    font-style: normal;
//    font-weight: 500;
//    src: local("Heebo Medium"), local("Heebo-Medium"), url(https://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9kFbqROkK440A.woff2) format("woff2");
//    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
//}

/* latin */
@font-face {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    src: local("Heebo Medium"), local("Heebo-Medium"), url("../../../fonts/heebo/heebo-medium.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

///* hebrew */
//@font-face {
//    font-family: Heebo;
//    font-style: normal;
//    font-weight: 800;
//    src: local("Heebo ExtraBold"), local("Heebo-ExtraBold"), url(https://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9lRa6ROkK440A.woff2) format("woff2");
//    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
//}

/* latin */
@font-face {
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    src: local("Heebo ExtraBold"), local("Heebo-ExtraBold"), url("../../../fonts/heebo/heebo-extrabold.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

///* hebrew */
//@font-face {
//    font-family: Heebo;
//    font-style: normal;
//    font-weight: 900;
//    src: local("Heebo Black"), local("Heebo-Black"), url(https://fonts.gstatic.com/s/heebo/v5/NGS3v5_NC0k9P9l1aqROkK440A.woff2) format("woff2");
//    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
//}

/* latin */
@font-face {
    font-family: Heebo;
    font-style: normal;
    font-weight: 900;
    src: local("Heebo Black"), local("Heebo-Black"), url("../../../fonts/heebo/heebo-black.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}