.news-filter {
    font-family: $font-family-extra;
    font-size: .85rem;
    margin: -25px 0 30px;
    padding-bottom: 35px;

    @include media-breakpoint-up(md) {
        margin: 0;
        padding-bottom: 70px;
        padding-top: 60px;
    }

    &__label {
        display: block;
        margin: 0 0 20px;
        text-align: center;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "secondary");

        @include media-breakpoint-up(md) {
            display: inline;
            margin: 0 15px 0 10px;
            text-align: left;
        }
    }

    &__category{
        margin: 5px 7px;

        &--active {
            background-color: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }
    }
}
