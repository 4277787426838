.text {
    margin: rem-calc(80) 0;

    &__team {
        margin-top: rem-calc(30);
    }

    &__team-bg {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: map-deep-get($colors, "brand", "secondary-lighter");
        }
    }

    &__team-title {
        margin: 40px 0 30px;
        font-weight: 500;
        font-size: rem-calc(14);
        line-height: 1.43em;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }

    &__button {
        margin: 50px 0 100px;
    }
}
