﻿// Colors
$colors: (
    "white": (
        "default": #ffffff
    ),
    "black": (
        "default": #1a1b1f
    ),
    "gray": (
        "light": #f5f5f5,
        "default": #646464,
        "dark": #262626,
        "input": #000000,
        "overlay": #313131
    ),
    "brand": (
        "primary": #222e50,
        "secondary": #2384a5,
        "secondary-light": #3e9aba,
        "secondary-lighter": #edf5f9,
        "secondary-dark": #2384a5
    ),
    "validation": (
        "valid": #5da399,
        "invalid": #e50044,
        "valid-dark": darken(#5da399, 25%),
        "invalid-dark": darken(#e50044, 25%)
    ),
    "social": (
        "facebook": #3b5998,
        "twitter": #1da1f2,
        "instagram": #c13584,
        "youtube": #ff0000,
        "linkedin": #0077b5
    ),
);

$body-bg: map-deep-get($colors, "white", "default");
$body-color: map-deep-get($colors, "black", "default");
$link-color: map-deep-get($colors, "brand", "primary");

// Fonts
$font-family-base: Heebo, "Arial", sans-serif;
$font-family-extra: Heebo, "Arial", sans-serif;

// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-cubic-smoothing: cubic-bezier(.2, 0, 0, 1);
$transition-cubic-time: .2s;
$transition-cubic: all $transition-cubic-time $transition-cubic-smoothing;

// Border Radius
$border-radius: 4px;

$enable-caret:              false;
$enable-shadows:            false;
$enable-rounded:            false;
$enable-gradients:          false;
$enable-transitions:        false;
$enable-hover-media-query:  false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true;
$enable-print-styles:       false;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1300px
);

$container-max-widths: (
    lg: 1200px
);

//
// Grid
$grid-columns: 24;
$grid-gutter-width: 20px;
