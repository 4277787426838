.persons {
    margin-top: 100px;
}

.person {
    $elm: &;
    position: relative;
    margin: auto;
    margin-bottom: 70px;
    max-width: 320px;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin-bottom: 90px;
    }

    &:hover {
        &::before {
            height: 4px;
        }
    }

    &__title-wrapper {
        margin-top: .7em;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary-dark");
        margin: 0;
        align-self: center;
    }

    &__functions {
        display: inline-block;
        margin: 1em 0;
        width: 100%;
        font-size: rem-calc(14);
        font-weight: 500;
        line-height: 1.43em;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }

    &__seperator {
        display: inline-block;
        width: 28px;
        height: 2px;
        border: 0;
        background: map-deep-get($colors, "brand", "secondary");
    }

    &__email,
    &__phone {
        display: inline-block;
        margin-bottom: .5em;
        width: 100%;
        font-size: rem-calc(14);
    }

    &__image {
        margin: 0 auto 40px;
        width: 120px;
        padding-top: 120px;
        max-width: 100%;
        border-radius: 50%;
        background-color: map-deep-get($colors, "gray", "light");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @include media-breakpoint-up(md) {
            width: 220px;
            padding-top: 220px;
        }

        @include media-breakpoint-up(lg) {
            width: 320px;
            padding-top: 100%;
        }
    }

    &__content {
        margin: 35px 0;
        line-height: 34px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .1;

        &:hover ~ #{$elm}__button {
            @extend .button--primary-ghost.hover;
        }
    }

    &__template-text {
        #{$elm}__title-wrapper {
            margin-top: 3em;
        }
    }
}

.person-detail {
    &__content {
        margin-top: 80px;
        margin-bottom: 60px;
    }

    &__button {
        margin-top: 20px;
    }
}
