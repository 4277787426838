.news-item {
    &__intro {
        padding: 50px 0 100px;

        @include media-breakpoint-up(md) {
            min-height: 480px;
            padding: 70px 0 60px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 380px;
        }
    }

    &__header {
        line-height: 37px;
        margin: 0 0 40px;
        position: relative;
        text-align: center;
        z-index: 1;

        @include media-breakpoint-up(md) {
            margin: 0 0 60px;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    &__share {
        color: map-deep-get($colors, "white", "default");
        line-height: rem-calc(40);

        &--margin {
            margin-bottom: 40px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        &--dark {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__back {
        text-align: center;
        @include media-breakpoint-down(md) {
            margin: 20px 0;
        }

        &--footer{
            text-align: left;
            margin: 50px 0 10px;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
    }

    &__categories {
        color: map-deep-get($colors, "brand", "secondary-dark");
        font-size: rem-calc(13);
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__title {
        margin-top: 0;
        color: map-deep-get($colors, "white", "default");
    }

    &__author {
        margin-top: 30px;
        margin-bottom: 30px;
        font-family: $font-family-extra;
        font-size: rem-calc(13);
        text-transform: uppercase;
    }

    &__image {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    &__content {
        margin-bottom: 100px;
    }

    &__bottom {
        margin-bottom: 80px;

        @include media-breakpoint-up(md) {
            margin-bottom: 160px;
        }
    }
}
