.socials{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-top: -4px;
    background-color: map-deep-get($colors, "brand", "secondary-dark");
    border-radius: 100%;
    vertical-align: middle;
    transition: $transition-default;

    &:not(:first-of-type) {
        margin-left: 7px;
    }

    &--in-text {
        margin-left: 5px;

        @include media-breakpoint-up(xl) {
            margin-left: 7px;
        }
    }

    &--dark {
        background-color: map-deep-get($colors, "black", "default");
    }

    &:hover {
        background-color: map-deep-get($colors, "brand", "secondary");
    }

    svg {
        width: 14px;
        height: 14px;
    }

    svg path {
        fill: map-deep-get($colors, "white", "default");
    }
}
