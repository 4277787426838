.expertises {
    $elm: &;
    padding-top: rem-calc(75);
    position: relative;

    &__wrapper {
        margin-bottom: rem-calc(50);

        @include media-breakpoint-up(md) {
            height: 90%;
            margin-bottom: 0;
            padding-bottom: 40px;
            position: relative;
        }

        /* stylelint-disable */
        h1,
        h2,
        h3 {
            font-size: rem-calc(24);
            color: map-deep-get($colors, "brand", "secondary-dark");

            @include media-breakpoint-up(md) {
                font-size: rem-calc(40);
            }
        }

        &:not(#{$elm}__wrapper--special) {
            h1,
            h2,
            h3 {
                margin-bottom: rem-calc(25);
            }
        }

        &--special {
            color: map-deep-get($colors, "white", "default");

            @include media-breakpoint-up(lg) {
                padding-left: 20px;
            }

            h1,
            h2,
            h3 {
                margin-bottom: rem-calc(30);
                margin-top: 1em;
                font-size: rem-calc(32);
                color: map-deep-get($colors, "white", "default");

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                    font-size: rem-calc(40);
                }

                @include media-breakpoint-up(lg) {
                    font-size: rem-calc(55);
                }
            }
        }
        /* stylelint-enable */
    }

    &__row {
        &--image {
            @include media-breakpoint-up(md) {
                margin-top: rem-calc(50);
            }
        }
    }

    &__special {
        position: relative;
        margin-top: 50px;
        padding-top: 120px;
        padding-bottom: 0;
        background: map-deep-get($colors, "brand", "secondary");

        @include media-breakpoint-up(md) {
            padding-top: 240px;
        }
    }

    &__shape1 {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        padding-top: 160px / 1440px * 100%;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__shape2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 58%;
        height: 70%;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__image-wrapper {
        left: 0;
        position: relative;
        width: 100vw;

        @include media-breakpoint-up(md) {
            bottom: 0;
            position: absolute;
            top: 0;
            width: 50vw;
        }

        &::before {
            background: rgba(map-deep-get($colors, "black", "default"), 0.3);
            bottom: 0;
            content: " ";
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        object-position: top;
        position: relative;
        width: 100%;
    }
}
