.home-team {
    position: relative;
    margin-top: 45px;
    margin-bottom: 45px;

    @include media-breakpoint-up(md) {
        margin-top: 60px;
        margin-bottom: 140px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 180px;
        bottom: -50px;
        left: 0;
        width: 100%;
        background: map-deep-get($colors, "brand", "secondary-lighter");

        @include media-breakpoint-up(md) {
            top: 280px;
            bottom: -140px;
        }

        @include media-breakpoint-up(lg) {
            top: 400px;
        }
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
        margin-bottom: rem-calc(55);
    }
}
