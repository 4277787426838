.home-title {
    text-align: center;
    margin: 8rem 0;

    &__wrapper {
        height: 70vh;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary");

        /* stylelint-disable */
        em {
            font-style: normal;
            color: map-deep-get($colors, "brand", "primary");
        }
        /* stylelint-enable */

        &--white {
            color: map-deep-get($colors, "white", "default");

            /* stylelint-disable */
            em {
                font-weight: 800;
                color: map-deep-get($colors, "white", "default");
            }
            /* stylelint-enable */
        }
    }
}
