.impression-editor {
    $elm: &;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &__radio {
        width: 0;
        height: 0;
        opacity: 0;

        &:checked + #{$elm}__tab {
            display: block;
        }
    }

    &__tabs {
        position: absolute;
        width: 300px;
        right: 100px;
        bottom: 100px;
        background: map-deep-get($colors, "white", "default");
        padding: 20px;
        padding-bottom: 13px;
        z-index: 20;
    }

    &__option {
        display: block;
    }

    &__tab {
        display: none;
        height: 100%;
        width: 100%;
        overflow: hidden;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &--mobile {
            background-size: contain;
            background-color: map-deep-get($colors, "gray", "default");
        }
    }

    &__content {
        visibility: hidden;
    }
}
